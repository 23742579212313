<template>
  <div class="catalogue-content">
    <div class="header">
      <div class="text">
        <h1>CATALOGUE</h1>
      </div>
    </div>
    <el-card class="box-card">
      <el-link type="info" :underline="false">View the last 30 days of catalogue data</el-link>
      <div v-if="catalogueCount" style="margin-top: 10px">
        <el-row :gutter="10">
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item
                v-for="item in catalogueList"
                :key="item.id"
                :name="item.id">
              <template slot="title">
                <el-link :href="item.url" type="primary" target="_blank"
                         class="collapseTitle"
                         title="Click on the new window to open the web link">{{item.no}}</el-link>
                <span style="margin-left: 10px" class="collapseTitle">{{item.create_date}} - {{item.expires_date}}</span>
              </template>
              <el-row :gutter="10">
                <el-col :xs="12" :sm="8" :md="6" :lg="4" :xl="3"
                    v-for="items in item.product_arr"
                    :key="items.id"
                    style="position: relative"
                >
                  <div class="userProductItem">
                    <!--主图预览-->
                    <el-image
                        class="proImg"
                        :src="items.picture"
                        :preview-src-list="[items.picture]"
                    >
                    </el-image>
                    <!--产品名称-->
                    <div
                        class="proName"
                        @click="toProductDetail(items.id)"
                        :title="items.name"
                    >
                      <span> {{ items.name }} </span>
                    </div>
                    <div class="proBox">
                      <!--产品编码-->
                      <div class="index">Item # {{ items.code }}</div>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-row>
        <el-pagination
          style="margin-top: 80px; text-align: right; width: 100%"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryData.page"
          :page-size="queryData.pageSize"
          :total="catalogueCount"
          layout="sizes,total, prev, pager, next,jumper"
        >
        </el-pagination>
      </div>
      <el-empty v-else description="You have not generated any catalogues yet"></el-empty>
<!--      <div >
        <div class="emptyBox">
          <el-image :src="emptyImg"> </el-image>
          <p></p>
        </div>
      </div>-->
    </el-card>
  </div>
</template>

<script>
import { userCatalogue } from "@/api/catalogue";
export default {
  data() {
    return {
      emptyImg: require("@/assets/imgs/Catalogue_empty.png"),
      catalogueList: [],
      catalogueCount: 0,
      activeName: '1',
      queryData: {
        page: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.getUserCatalogue();
  },
  methods: {
    getUserCatalogue() {
      const token = localStorage.getItem("token");
      userCatalogue({
        token: token,
        pageSize: this.queryData.pageSize,
        page: this.queryData.page,
        price_type: localStorage.getItem("price_type"),
      }).then((res) => {
        this.catalogueList = res.data.list;
        this.catalogueCount = res.data.count;
      });
    },
    handleSizeChange(val) {
      this.queryData.pageSize = val;
      this.getUserCatalogue();
    },
    //当前页改变时触发 跳转其他页
    handleCurrentChange(val) {
      this.queryData.page = val;
      this.getUserCatalogue();
    },

    toProductDetail(id) {
      this.$router.push({
        path: "/product-detail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.emptyBox {
  text-align: center;
  color: #666a6b;
}
.userProductItem {
  border: solid 1px #ccc;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
}
.proName {
  color: #176f95;
  flex: 1 1 auto;
  font-size: 1.5167rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  line-clamp: 1;
  cursor: pointer;
  height: 55px;
  //width: 200px !important; //限制文本宽度
  word-wrap: break-word; //自动换行
  word-break: break-all; //自动换行（兼容字母）
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //溢出显示省略号
  display: -webkit-box;
  -webkit-line-clamp: 2; //显示2行
  -webkit-box-orient: vertical;
}
.proName :hover {
  color: #275366;
  text-decoration: underline;
}
.proImg {
  width: 182px;
  height: 182px;
}
.proBox {
  color: #666a6b !important;
  font-size: 11px;
}
.collapseTitle {
  font-size: 16px;
  font-weight:bold;
}
.header {
  background: url(../../assets/imgs/home/texture-bw.png) center center repeat;
  height: 113px;
  .text {
    max-width: 1170px;
    margin: 0 auto;
    line-height: 113px;
    font-size: 20px;
  }
  h1 {
    font-weight: bolder;
    line-height: 113px;
    margin-top: 0px;
  }
}
</style>
